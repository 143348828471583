import React, { useEffect, useContext, useRef } from "react"
import { graphql } from "gatsby"
import { useIntl } from "react-intl"

import Layout from "../layout"
import SEO from "../seo"
import EmailCaptureModal from "../email-capture-modal"

import { IntlContext } from "../../intl"

export const query = graphql`
	query($id: String!) {
		prismicAbout(id: { eq: $id }) {
      uid
      url
      lang
			data {
				title {
					text
				}
				headline {
					text
				}
        above_the_fold_image {
          alt
          fluid {
            src
          }
        }
				content {
					html
				}
        body {
          __typename
          ... on PrismicAboutBodyTitledText {
            slice_type
            items {
              subtitle {
                text
              }
              text {
                html
              }
            }
          }
        }
			}
      alternate_languages {
        lang
        url
        document {
          __typename
          ... on PrismicAbout {
            data {
              title {
                text
              }
            }
          }
        }
      }
		}
	}
`

const AboutPage = ({ data }) => {

  const intl = useIntl()
  const modalTriggerRef1 = useRef()
  const modalTriggerRef2 = useRef()

  const { changeLocale, setAlts } = useContext(IntlContext)
  useEffect(() => {
    changeLocale(data.prismicAbout.lang);
    setAlts(data.prismicAbout.alternate_languages || [])
  }, [changeLocale, setAlts, data])
  
  return (
	<Layout container={false}>
    <SEO 
      lang={data.prismicAbout.lang} 
      title={data.prismicAbout.data.title.text} 
      meta={[{
        "http-equiv": "content-language", 
        content: data.prismicAbout.lang
      }]} 
      links={data.prismicAbout.alternate_languages.concat([data.prismicAbout]).map(alt => ({
        rel: "alternate", 
        hreflang: alt.lang, 
        href: `https://www.customerradius.com${alt.url}`
      })).concat([{
        rel: "alternate", 
        hreflang: "x-default", 
        href: "https://www.customerradius.com/about"
      }])} />
    <section className="hero is-fullheight-with-navbar">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title">
            {data.prismicAbout.data.title.text}
          </h1>
          <button className="button is-warning is-large" ref={modalTriggerRef1}>
            {intl.formatMessage({ id: "email_capture.request_early_access" })}
          </button>
          <EmailCaptureModal triggerRef={modalTriggerRef1} intl={intl} sourceDetail={'About'} />
        </div>
      </div>
      <div className="hero-footer">
        <div className="container">
          <div className="columns is-flex-reverse-touch">
            <div className="column is-two-fifths-desktop">
              <div className="section">
                <div className="content is-large" dangerouslySetInnerHTML={{ __html: data.prismicAbout.data.content.html }}></div>
              </div>
            </div>
            <div className="column is-three-fifths-desktop py-5">
              <figure className="image">
                <img src={data.prismicAbout.data.above_the_fold_image.fluid.src}
                  alt={data.prismicAbout.data.above_the_fold_image.alt} />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
		<div className="hero is-primary is-medium">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h2 className="title is-size-2-desktop is-size-3-touch">
            {data.prismicAbout.data.headline.text}
          </h2>
        </div>
      </div>
    </div>
		<div className="section">
      <div className="container">
        <div className="column is-three-fifths is-offset-one-fifth">
          {data.prismicAbout.data.body.map((slice, i) => {
            if (slice.slice_type === 'titled_text') {
              return (
                <div className="notification" key={i}>
                  {slice.items.map((item, i) => {
                    return (
                      <div className="content is-medium" key={i}>
                        <h4>{item.subtitle.text}</h4>
                        <div dangerouslySetInnerHTML={{ __html: item.text.html }}></div>
                      </div>
                    )
                  })}
                </div>
              )
            } else {
              return null
            }
          })}
        </div>
      </div>
		</div>
    <div className="hero is-warning">
      <div className="hero-body has-text-centered">
        <button className="button is-dark is-large" ref={modalTriggerRef2}>
          {intl.formatMessage({ id: "email_capture.request_early_access" })}
        </button>
        <EmailCaptureModal triggerRef={modalTriggerRef2} intl={intl} sourceDetail={'About'} />
      </div>
    </div>
	</Layout>
)}

export default AboutPage
