import React, { useState, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useMutation, gql } from "@apollo/client"

import { useEventListener } from "../hooks/useEventListener"

const SUBMIT_EMAIL_CAPTURE_FORM = gql`
  mutation SubmitEmailCaptureForm($input: EmailCaptureFormInput!) {
    submitEmailCaptureForm(input: $input)
  }
`

const EmailCaptureModal = ({ triggerRef, intl, source='Website', sourceDetail='' }) => {

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')

	const [showInvalidEmail, setShowInvalidEmail] = useState(false)
	const [showSuccess, setShowSuccess] = useState(false)
	const [showError, setShowError] = useState(false)
  
  const modalRef = useRef()
  const modalBackgroundRef = useRef()
  const modalCloseRef = useRef()

  // handle modal open
  const modalOpenListener = (e) => {
    e.preventDefault();
    document.getElementsByTagName('html')[0].classList.add('is-clipped');
    modalRef.current.classList.add('is-active');
  }
  useEventListener('click', modalOpenListener, triggerRef)

  // handle modal close
  const modalCloseListener = (e) => {
    e.preventDefault();
    document.getElementsByTagName('html')[0].classList.remove('is-clipped');
    modalRef.current.classList.remove('is-active');
    
    // reset form
    setFirstName('');
    setLastName('');
    setEmail('');
    setShowInvalidEmail(false);
    setShowSuccess(false);
    setShowError(false);
  }
  useEventListener('click', modalCloseListener, modalBackgroundRef)
  useEventListener('click', modalCloseListener, modalCloseRef)

  const submitForm = (e) => {
		e.preventDefault();

    const validEmail = /\S+@\S+\.\S+/.test(email)
    
    setShowSuccess(false);
    setShowError(false);
    setShowInvalidEmail(!validEmail);

    const language = {"en-us":"English", "es-us":"Spanish", "pt-us":"Portuguese"}[intl.locale]
		
    if (validEmail) submitEmailCaptureForm({ variables: {input: { firstName, lastName, email, source, sourceDetail, language }} })
  }
  
  // submit email capture form
  const [submitEmailCaptureForm, {loading: loadingSubmitEmailCaptureForm}] = useMutation(SUBMIT_EMAIL_CAPTURE_FORM, {
    onCompleted: (data) => {
      // console.log(data)
      
      setLastName('')
      setFirstName('')
      setEmail('')
      setShowSuccess(true)
      setShowError(false)
      setShowInvalidEmail(false)
    },
    onError: (error) => {
      // TODO: log error
      // TODO: show error count
      // TODO: fix apollo to throw error after too many attempts or service unreachable!
      setShowError(true)
    }
  })

  const data = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "customer-radius-logo.png" }) {
        childImageSharp {
          fixed(width: 128) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <>
      <div className="modal" ref={modalRef}>
        <div className="modal-background" ref={modalBackgroundRef}></div>
        <div className="modal-content">
          <div className="column">
            <div className="box has-text-centered" style={{overflowX:"hidden"}}>
              <div className="column">
                <Img fixed={data.logoImage.childImageSharp.fixed} />
              </div>
              <h2 className="subtitle has-text-black">
                {intl.formatMessage({ id: "email_capture.headline" })}
              </h2>
              {!showSuccess &&
                <form className="has-text-left" onSubmit={submitForm}>
                  <div className="columns is-variable is-1 mb-0">
                    <div className="column field mb-0">
                      <label className="label has-text-weight-medium">{intl.formatMessage({ id: "email_capture.first_name" })}</label>
                      <div className="control is-expanded">
                        <input className="input" type="text" name="first_name" maxLength="50" 
                          placeholder={intl.formatMessage({ id: "email_capture.first_name" })}
                          value={firstName}
                          onChange={e=>setFirstName(e.target.value)} />
                      </div>
                    </div>
                    <div className="column field mb-0">
                      <label className="label has-text-weight-medium">{intl.formatMessage({ id: "email_capture.last_name" })}</label>
                      <div className="control is-expanded">
                        <input className="input" type="text" name="last_name" maxLength="50" 
                          placeholder={intl.formatMessage({ id: "email_capture.last_name" })}
                          value={lastName}
                          onChange={e=>setLastName(e.target.value)} />
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label has-text-weight-medium">{intl.formatMessage({ id: "email_capture.email_address" })}</label>
                    <div className="control">
                      <input className={showInvalidEmail ? 'input is-danger' : 'input'} type="text" name="email" maxLength="50" 
                        placeholder="Email"
                        value={email}
                        onChange={e=>setEmail(e.target.value)} />
                        {showInvalidEmail &&
                          <p className="help is-danger">{intl.formatMessage({ id: "contact_form.valid_email_required" })}</p>
                        }
                    </div>
                  </div>
                  {showError &&
                    <div className="notification is-danger">
                      <button className="delete" name="error" aria-label="close" onClick={()=>setShowError(false)}></button>
                      {intl.formatMessage({ id: "email_capture.error" })}
                    </div>
                  }
                  <div className="field">
                    <div className="control has-text-centered">
                      <button className={loadingSubmitEmailCaptureForm?"button is-rounded is-info is-medium is-loading":"button is-rounded is-info is-medium"} type="submit">
                        {intl.formatMessage({ id: "email_capture.request_early_access" })}
                      </button>
                    </div>
                  </div>
                </form>
              }
              {showSuccess && 
                <>
                  <div className="notification is-success">
                    {intl.formatMessage({ id: "email_capture.success" })}
                  </div>
                  <div className="has-text-centered">
                    <button className="button is-rounded is-small" onClick={modalCloseListener}>
                      {intl.formatMessage({ id: "email_capture.close" })}
                    </button>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
        <button className="modal-close is-large" ref={modalCloseRef} aria-label="close"></button>
      </div>
    </>
  )
}

export default EmailCaptureModal
