import { useEffect, useRef } from "react"

// removed default element = typeof window !== 'undefined' && window
export const useEventListener = (eventName, handler, elementRef) => {
  const _handler = useRef();

  useEffect(() => {
    _handler.current = handler;
  }, [handler]);

  useEffect(() => {

    // Make sure element supports addEventListener
    const element = elementRef && elementRef.current
    const isSupported = element && element.addEventListener
    if (!isSupported) return;

    // Create event listener that passes event to current handler
    const eventListener = event => _handler.current(event);

    // Add event listener
    element.addEventListener(eventName, eventListener);

    // Remove event listener on cleanup
    return () => {
      element.removeEventListener(eventName, eventListener);
    }
  }, [eventName, elementRef])
}
